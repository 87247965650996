import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "background": "#080808"
    },
    "index": {
        "background": "#080808"
    },
    "__template__": {
        "background": "#080808"
    },
    "lp": {
        "background": "#080808"
    },
    "lp/autograph-creator": {
        "background": "#080808"
    },
    "lp/autograph-design": {
        "background": "#080808"
    },
    "lp/autograph-design-for-your-name": {
        "background": "#080808"
    },
    "lp/autograph-designer": {
        "background": "#080808"
    },
    "lp/autograph-ideas-for-your-name": {
        "background": "#080808"
    },
    "lp/autograph-maker": {
        "background": "#080808"
    },
    "lp/awesome-signatures-for-your-name": {
        "background": "#080808"
    },
    "lp/beautiful-signature": {
        "background": "#080808"
    },
    "lp/beautiful-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/beautiful-signature-ideas": {
        "background": "#080808"
    },
    "lp/beautiful-signature-maker": {
        "background": "#080808"
    },
    "lp/best-professional-signature": {
        "background": "#080808"
    },
    "lp/best-signature-design": {
        "background": "#080808"
    },
    "lp/best-signature-design-for-your-name": {
        "background": "#080808"
    },
    "lp/best-signature-design-service": {
        "background": "#080808"
    },
    "lp/best-signature-maker": {
        "background": "#080808"
    },
    "lp/caligrapher-signature": {
        "background": "#080808"
    },
    "lp/caligraphic-signature": {
        "background": "#080808"
    },
    "lp/caligraphy-signature-creator": {
        "background": "#080808"
    },
    "lp/calligraphic-signature": {
        "background": "#080808"
    },
    "lp/calligraphy-designer": {
        "background": "#080808"
    },
    "lp/calligraphy-professional": {
        "background": "#080808"
    },
    "lp/calligraphy-services": {
        "background": "#080808"
    },
    "lp/calligraphy-signature": {
        "background": "#080808"
    },
    "lp/calligraphy-signature-creator": {
        "background": "#080808"
    },
    "lp/calligraphy-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/calligraphy-signature-maker": {
        "background": "#080808"
    },
    "lp/calligraphy-signature-maker-online": {
        "background": "#080808"
    },
    "lp/calligraphy-signature-online": {
        "background": "#080808"
    },
    "lp/calligraphy-your-signature": {
        "background": "#080808"
    },
    "lp/complex-signature-maker": {
        "background": "#080808"
    },
    "lp/cool-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/cool-signature-generator": {
        "background": "#080808"
    },
    "lp/cool-signatures-for-your-name": {
        "background": "#080808"
    },
    "lp/cool-ways-to-write-your-signature": {
        "background": "#080808"
    },
    "lp/create-a-beautiful-signature": {
        "background": "#080808"
    },
    "lp/create-a-better-signature": {
        "background": "#080808"
    },
    "lp/create-a-calligraphy-signature": {
        "background": "#080808"
    },
    "lp/create-a-cool-signature": {
        "background": "#080808"
    },
    "lp/create-a-cool-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-a-cursive-signature": {
        "background": "#080808"
    },
    "lp/create-a-custom-signature": {
        "background": "#080808"
    },
    "lp/create-a-good-signature": {
        "background": "#080808"
    },
    "lp/create-a-good-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-a-handwritten-signature": {
        "background": "#080808"
    },
    "lp/create-a-handwritten-signature-for-you": {
        "background": "#080808"
    },
    "lp/create-a-handwritten-signature-online": {
        "background": "#080808"
    },
    "lp/create-a-new-signature": {
        "background": "#080808"
    },
    "lp/create-a-new-signature-for-yourself": {
        "background": "#080808"
    },
    "lp/create-a-nice-signature": {
        "background": "#080808"
    },
    "lp/create-a-personal-signature": {
        "background": "#080808"
    },
    "lp/create-a-personalized-signature": {
        "background": "#080808"
    },
    "lp/create-a-professional-signature": {
        "background": "#080808"
    },
    "lp/create-a-signature": {
        "background": "#080808"
    },
    "lp/create-a-signature-design": {
        "background": "#080808"
    },
    "lp/create-a-signature-for-you": {
        "background": "#080808"
    },
    "lp/create-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-a-signature-from-name": {
        "background": "#080808"
    },
    "lp/create-a-signature-with-your-name": {
        "background": "#080808"
    },
    "lp/create-a-unique-signature": {
        "background": "#080808"
    },
    "lp/create-a-unique-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-amazing-signature": {
        "background": "#080808"
    },
    "lp/create-an-amazing-personal-signature": {
        "background": "#080808"
    },
    "lp/create-an-amazing-personal-signature-with-professional-calligraphers": {
        "background": "#080808"
    },
    "lp/create-an-amazing-signature": {
        "background": "#080808"
    },
    "lp/create-autograph-of-your-name": {
        "background": "#080808"
    },
    "lp/create-best-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-calligraphy-signature-online": {
        "background": "#080808"
    },
    "lp/create-cool-signature": {
        "background": "#080808"
    },
    "lp/create-cool-signature-online": {
        "background": "#080808"
    },
    "lp/create-creative-signature": {
        "background": "#080808"
    },
    "lp/create-cursive-signature-online": {
        "background": "#080808"
    },
    "lp/create-fancy-signature": {
        "background": "#080808"
    },
    "lp/create-handwriting-signature": {
        "background": "#080808"
    },
    "lp/create-handwritten-signature-online": {
        "background": "#080808"
    },
    "lp/create-personal-signature-online": {
        "background": "#080808"
    },
    "lp/create-signature-calligraphy": {
        "background": "#080808"
    },
    "lp/create-signature-design": {
        "background": "#080808"
    },
    "lp/create-signature-design-for-your-name": {
        "background": "#080808"
    },
    "lp/create-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/create-stylish-signature": {
        "background": "#080808"
    },
    "lp/create-you-a-signature": {
        "background": "#080808"
    },
    "lp/create-you-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/create-your-autograph": {
        "background": "#080808"
    },
    "lp/create-your-handwritten-signature": {
        "background": "#080808"
    },
    "lp/create-your-name-signature": {
        "background": "#080808"
    },
    "lp/create-your-own-autograph": {
        "background": "#080808"
    },
    "lp/create-your-own-handwritten-signature": {
        "background": "#080808"
    },
    "lp/create-your-own-signature": {
        "background": "#080808"
    },
    "lp/create-your-signature": {
        "background": "#080808"
    },
    "lp/create-your-signature-calligraphy": {
        "background": "#080808"
    },
    "lp/create-your-signature-for-you": {
        "background": "#080808"
    },
    "lp/create-your-signature-style": {
        "background": "#080808"
    },
    "lp/creating-a-good-signature": {
        "background": "#080808"
    },
    "lp/creating-a-personal-signature": {
        "background": "#080808"
    },
    "lp/creating-a-professional-signature": {
        "background": "#080808"
    },
    "lp/creating-a-unique-signature": {
        "background": "#080808"
    },
    "lp/creating-an-autograph": {
        "background": "#080808"
    },
    "lp/creating-signatures": {
        "background": "#080808"
    },
    "lp/creating-your-own-signature": {
        "background": "#080808"
    },
    "lp/creative-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/creative-signatures-for-your-name": {
        "background": "#080808"
    },
    "lp/cursive-signature-creator": {
        "background": "#080808"
    },
    "lp/cursive-signature-helper": {
        "background": "#080808"
    },
    "lp/cursive-signature-maker": {
        "background": "#080808"
    },
    "lp/custom-calligraphy-signature": {
        "background": "#080808"
    },
    "lp/custom-designed-signature": {
        "background": "#080808"
    },
    "lp/custom-handwriting-sign": {
        "background": "#080808"
    },
    "lp/custom-handwritten-signature": {
        "background": "#080808"
    },
    "lp/custom-made-signature": {
        "background": "#080808"
    },
    "lp/custom-signature": {
        "background": "#080808"
    },
    "lp/custom-signature-creator": {
        "background": "#080808"
    },
    "lp/custom-signature-design": {
        "background": "#080808"
    },
    "lp/custom-signature-designer": {
        "background": "#080808"
    },
    "lp/custom-signature-maker": {
        "background": "#080808"
    },
    "lp/custom-signature-online": {
        "background": "#080808"
    },
    "lp/custom-signature-service": {
        "background": "#080808"
    },
    "lp/customize-signature": {
        "background": "#080808"
    },
    "lp/customize-your-signature": {
        "background": "#080808"
    },
    "lp/customized-signature": {
        "background": "#080808"
    },
    "lp/design-a-new-signature": {
        "background": "#080808"
    },
    "lp/design-a-signature": {
        "background": "#080808"
    },
    "lp/design-a-signature-for-you": {
        "background": "#080808"
    },
    "lp/design-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/design-an-autograph": {
        "background": "#080808"
    },
    "lp/design-autograph": {
        "background": "#080808"
    },
    "lp/design-handwritten-signature": {
        "background": "#080808"
    },
    "lp/design-personal-signature": {
        "background": "#080808"
    },
    "lp/design-professional-signature": {
        "background": "#080808"
    },
    "lp/design-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/design-signature-online": {
        "background": "#080808"
    },
    "lp/design-signatures": {
        "background": "#080808"
    },
    "lp/design-you-a-signature": {
        "background": "#080808"
    },
    "lp/design-your-handwritten-signature": {
        "background": "#080808"
    },
    "lp/design-your-own-signature": {
        "background": "#080808"
    },
    "lp/design-your-signature": {
        "background": "#080808"
    },
    "lp/design-your-signature-calligraphy": {
        "background": "#080808"
    },
    "lp/design-your-signature-online": {
        "background": "#080808"
    },
    "lp/designs-for-signature": {
        "background": "#080808"
    },
    "lp/different-signatures-for-your-name": {
        "background": "#080808"
    },
    "lp/fancy-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/find-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/generate-a-signature-for-you": {
        "background": "#080808"
    },
    "lp/generate-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/generate-calligraphy-signature": {
        "background": "#080808"
    },
    "lp/generate-handwritten-signature": {
        "background": "#080808"
    },
    "lp/get-a-better-signature": {
        "background": "#080808"
    },
    "lp/get-a-custom-signature": {
        "background": "#080808"
    },
    "lp/get-a-professional-signature": {
        "background": "#080808"
    },
    "lp/get-a-signature-design": {
        "background": "#080808"
    },
    "lp/get-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/get-your-own-signature": {
        "background": "#080808"
    },
    "lp/good-signature-style": {
        "background": "#080808"
    },
    "lp/good-signatures-for-your-name": {
        "background": "#080808"
    },
    "lp/handcrafted-signature": {
        "background": "#080808"
    },
    "lp/handwriting-name-signature": {
        "background": "#080808"
    },
    "lp/handwriting-signature": {
        "background": "#080808"
    },
    "lp/handwriting-signature-creator": {
        "background": "#080808"
    },
    "lp/handwriting-signature-design": {
        "background": "#080808"
    },
    "lp/handwriting-signature-online": {
        "background": "#080808"
    },
    "lp/handwriting-signatures": {
        "background": "#080808"
    },
    "lp/handwritten-beautiful-signature": {
        "background": "#080808"
    },
    "lp/handwritten-beautiful-signatures": {
        "background": "#080808"
    },
    "lp/handwritten-calligraphy-services": {
        "background": "#080808"
    },
    "lp/handwritten-cursive-signature": {
        "background": "#080808"
    },
    "lp/handwritten-professional-signature": {
        "background": "#080808"
    },
    "lp/handwritten-signature": {
        "background": "#080808"
    },
    "lp/handwritten-signature-creator": {
        "background": "#080808"
    },
    "lp/handwritten-signature-design": {
        "background": "#080808"
    },
    "lp/handwritten-signature-design-for-your-name": {
        "background": "#080808"
    },
    "lp/handwritten-signature-designer": {
        "background": "#080808"
    },
    "lp/handwritten-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/handwritten-signature-ideas-for-your-name": {
        "background": "#080808"
    },
    "lp/handwritten-signature-ideas-for-your-name-online": {
        "background": "#080808"
    },
    "lp/handwritten-signature-maker": {
        "background": "#080808"
    },
    "lp/handwritten-signature-online": {
        "background": "#080808"
    },
    "lp/help-create-a-signature": {
        "background": "#080808"
    },
    "lp/help-create-signature": {
        "background": "#080808"
    },
    "lp/help-with-signature": {
        "background": "#080808"
    },
    "lp/help-with-signature-handwriting": {
        "background": "#080808"
    },
    "lp/help-with-your-signature": {
        "background": "#080808"
    },
    "lp/help-you-create-a-signature": {
        "background": "#080808"
    },
    "lp/help-you-design-your-signature": {
        "background": "#080808"
    },
    "lp/help-you-with-your-signature": {
        "background": "#080808"
    },
    "lp/how-can-you-make-your-signature-better": {
        "background": "#080808"
    },
    "lp/how-do-i-create-a-unique-signature": {
        "background": "#080808"
    },
    "lp/how-to-come-up-with-a-cool-signature": {
        "background": "#080808"
    },
    "lp/how-to-come-up-with-your-own-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-beautiful-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-beautiful-signature-for-yourself": {
        "background": "#080808"
    },
    "lp/how-to-create-a-better-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-cool-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-custom-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-fancy-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-good-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-great-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-hand-written-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-handwritten-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-nice-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-personal-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-professional-handwritten-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/how-to-create-a-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-create-a-unique-handwritten-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-a-unique-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-an-awesome-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-good-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-handwritten-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-new-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-nice-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-personal-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-your-own-autograph": {
        "background": "#080808"
    },
    "lp/how-to-create-your-own-handwritten-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-your-own-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-create-your-own-unique-signature": {
        "background": "#080808"
    },
    "lp/how-to-create-your-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-design-a-new-signature": {
        "background": "#080808"
    },
    "lp/how-to-design-a-signature-handwriting": {
        "background": "#080808"
    },
    "lp/how-to-design-signature-for-name": {
        "background": "#080808"
    },
    "lp/how-to-design-your-own-amazing-signature": {
        "background": "#080808"
    },
    "lp/how-to-design-your-own-signature": {
        "background": "#080808"
    },
    "lp/how-to-design-your-signature": {
        "background": "#080808"
    },
    "lp/how-to-develop-a-good-signature": {
        "background": "#080808"
    },
    "lp/how-to-develop-a-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-develop-signature": {
        "background": "#080808"
    },
    "lp/how-to-do-a-good-signature": {
        "background": "#080808"
    },
    "lp/how-to-do-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-do-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-find-your-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-get-a-better-signature": {
        "background": "#080808"
    },
    "lp/how-to-get-a-cool-signature": {
        "background": "#080808"
    },
    "lp/how-to-get-a-new-signature": {
        "background": "#080808"
    },
    "lp/how-to-get-a-nice-signature": {
        "background": "#080808"
    },
    "lp/how-to-get-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-a-beautiful-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-a-better-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-a-pretty-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-a-unique-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-good-signature": {
        "background": "#080808"
    },
    "lp/how-to-have-nice-signature": {
        "background": "#080808"
    },
    "lp/how-to-improve-signature": {
        "background": "#080808"
    },
    "lp/how-to-improve-your-signature": {
        "background": "#080808"
    },
    "lp/how-to-improve-your-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-make-a-beautiful-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-better-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-custom-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-good-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/how-to-make-a-handwritten-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-perfect-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-pretty-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/how-to-make-a-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/how-to-make-a-unique-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-an-amazing-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-an-autograph-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-an-elegant-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-calligraphy-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-fancy-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-good-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/how-to-make-nice-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-unique-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-your-autograph": {
        "background": "#080808"
    },
    "lp/how-to-make-your-own-autograph": {
        "background": "#080808"
    },
    "lp/how-to-make-your-own-signature-style": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature-better": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature-cool": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature-fancy": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature-look-professional": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature-stylish": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signature-unique": {
        "background": "#080808"
    },
    "lp/how-to-make-your-signiture": {
        "background": "#080808"
    },
    "lp/how-to-perfect-a-signature": {
        "background": "#080808"
    },
    "lp/how-to-perfect-your-signature": {
        "background": "#080808"
    },
    "lp/how-to-sign-a-good-signature": {
        "background": "#080808"
    },
    "lp/how-to-sign-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-sign-your-name-like-a-professional": {
        "background": "#080808"
    },
    "lp/how-to-sign-your-name-professionally": {
        "background": "#080808"
    },
    "lp/how-to-write-a-better-signature": {
        "background": "#080808"
    },
    "lp/how-to-write-a-fancy-signature": {
        "background": "#080808"
    },
    "lp/how-to-write-a-nice-signature": {
        "background": "#080808"
    },
    "lp/how-to-write-a-pretty-signature": {
        "background": "#080808"
    },
    "lp/how-to-write-a-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-write-professional-signature": {
        "background": "#080808"
    },
    "lp/how-to-write-your-signature": {
        "background": "#080808"
    },
    "lp/impressive-signature-styles": {
        "background": "#080808"
    },
    "lp/improve-signature": {
        "background": "#080808"
    },
    "lp/improve-signature-handwriting": {
        "background": "#080808"
    },
    "lp/improve-signature-online": {
        "background": "#080808"
    },
    "lp/improve-your-signature": {
        "background": "#080808"
    },
    "lp/learn-how-to-do-your-signature": {
        "background": "#080808"
    },
    "lp/learn-how-to-write-your-signature": {
        "background": "#080808"
    },
    "lp/make-a-beautiful-signature": {
        "background": "#080808"
    },
    "lp/make-a-cool-signature": {
        "background": "#080808"
    },
    "lp/make-a-custom-signature": {
        "background": "#080808"
    },
    "lp/make-a-fancy-signature": {
        "background": "#080808"
    },
    "lp/make-a-new-signature": {
        "background": "#080808"
    },
    "lp/make-a-professional-signature": {
        "background": "#080808"
    },
    "lp/make-a-signature": {
        "background": "#080808"
    },
    "lp/make-a-signature-for-you": {
        "background": "#080808"
    },
    "lp/make-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/make-own-signature": {
        "background": "#080808"
    },
    "lp/make-signature-for-you": {
        "background": "#080808"
    },
    "lp/make-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/make-unique-signature": {
        "background": "#080808"
    },
    "lp/make-you-a-cool-signature": {
        "background": "#080808"
    },
    "lp/make-you-a-new-signature": {
        "background": "#080808"
    },
    "lp/make-you-a-signature": {
        "background": "#080808"
    },
    "lp/make-you-a-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/make-your-name-into-a-signature": {
        "background": "#080808"
    },
    "lp/make-your-own-autograph": {
        "background": "#080808"
    },
    "lp/make-your-own-signature": {
        "background": "#080808"
    },
    "lp/make-your-signature": {
        "background": "#080808"
    },
    "lp/make-your-signature-better": {
        "background": "#080808"
    },
    "lp/make-your-signature-cool": {
        "background": "#080808"
    },
    "lp/make-your-signature-for-you": {
        "background": "#080808"
    },
    "lp/make-your-signature-look-professional": {
        "background": "#080808"
    },
    "lp/making-a-good-signature": {
        "background": "#080808"
    },
    "lp/making-your-own-signature": {
        "background": "#080808"
    },
    "lp/name-signature-design": {
        "background": "#080808"
    },
    "lp/name-to-signature": {
        "background": "#080808"
    },
    "lp/need-a-new-signature": {
        "background": "#080808"
    },
    "lp/new-signature-calligraphy": {
        "background": "#080808"
    },
    "lp/nice-signatures": {
        "background": "#080808"
    },
    "lp/nice-signatures-for-your-name": {
        "background": "#080808"
    },
    "lp/online-cursive-signature": {
        "background": "#080808"
    },
    "lp/online-handwritten-signature-maker": {
        "background": "#080808"
    },
    "lp/online-professional-signature-maker": {
        "background": "#080808"
    },
    "lp/online-signature-design": {
        "background": "#080808"
    },
    "lp/online-signature-design-for-your-name": {
        "background": "#080808"
    },
    "lp/online-signature-designer": {
        "background": "#080808"
    },
    "lp/perfect-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/personal-signature": {
        "background": "#080808"
    },
    "lp/personal-signature-creator": {
        "background": "#080808"
    },
    "lp/personal-signature-design": {
        "background": "#080808"
    },
    "lp/personal-signature-ideas": {
        "background": "#080808"
    },
    "lp/personal-signature-maker": {
        "background": "#080808"
    },
    "lp/personal-signature-with-professional-calligraphers": {
        "background": "#080808"
    },
    "lp/personalised-signatures": {
        "background": "#080808"
    },
    "lp/personalized-signature": {
        "background": "#080808"
    },
    "lp/premium-signature-services": {
        "background": "#080808"
    },
    "lp/professional-autograph": {
        "background": "#080808"
    },
    "lp/professional-business-signature": {
        "background": "#080808"
    },
    "lp/professional-calligrapher": {
        "background": "#080808"
    },
    "lp/professional-calligraphy-signature": {
        "background": "#080808"
    },
    "lp/professional-hand-signature": {
        "background": "#080808"
    },
    "lp/professional-handwritten-signature": {
        "background": "#080808"
    },
    "lp/professional-looking-signature": {
        "background": "#080808"
    },
    "lp/professional-signature": {
        "background": "#080808"
    },
    "lp/professional-signature-creator": {
        "background": "#080808"
    },
    "lp/professional-signature-creator-online": {
        "background": "#080808"
    },
    "lp/professional-signature-design": {
        "background": "#080808"
    },
    "lp/professional-signature-designer": {
        "background": "#080808"
    },
    "lp/professional-signature-for-emails": {
        "background": "#080808"
    },
    "lp/professional-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/professional-signature-generator": {
        "background": "#080808"
    },
    "lp/professional-signature-ideas": {
        "background": "#080808"
    },
    "lp/professional-signature-maker": {
        "background": "#080808"
    },
    "lp/professional-signature-of-your-name": {
        "background": "#080808"
    },
    "lp/professional-signature-service": {
        "background": "#080808"
    },
    "lp/professional-signature-style": {
        "background": "#080808"
    },
    "lp/professional-signiture": {
        "background": "#080808"
    },
    "lp/professional-written-signature": {
        "background": "#080808"
    },
    "lp/real-signature-maker": {
        "background": "#080808"
    },
    "lp/short-signature-for-your-name": {
        "background": "#080808"
    },
    "lp/signage-design-ideas": {
        "background": "#080808"
    },
    "lp/signature-by-calligrapher": {
        "background": "#080808"
    },
    "lp/signature-calligrapher": {
        "background": "#080808"
    },
    "lp/signature-calligraphy-design": {
        "background": "#080808"
    },
    "lp/signature-calligraphy-service": {
        "background": "#080808"
    },
    "lp/signature-creation": {
        "background": "#080808"
    },
    "lp/signature-creation-service": {
        "background": "#080808"
    },
    "lp/signature-creator": {
        "background": "#080808"
    },
    "lp/signature-creator-for-your-name": {
        "background": "#080808"
    },
    "lp/signature-design-company": {
        "background": "#080808"
    },
    "lp/signature-design-for-your-name": {
        "background": "#080808"
    },
    "lp/signature-design-for-your-name-online": {
        "background": "#080808"
    },
    "lp/signature-design-maker": {
        "background": "#080808"
    },
    "lp/signature-design-online": {
        "background": "#080808"
    },
    "lp/signature-design-service": {
        "background": "#080808"
    },
    "lp/signature-designers": {
        "background": "#080808"
    },
    "lp/signature-developer-online": {
        "background": "#080808"
    },
    "lp/signature-for-your-name": {
        "background": "#080808"
    },
    "lp/signature-generator-for-your-name-calligraphy": {
        "background": "#080808"
    },
    "lp/signature-idea-for-your-name": {
        "background": "#080808"
    },
    "lp/signature-improver": {
        "background": "#080808"
    },
    "lp/signature-in-calligraphy-style": {
        "background": "#080808"
    },
    "lp/signature-maker": {
        "background": "#080808"
    },
    "lp/signature-maker-calligraphy": {
        "background": "#080808"
    },
    "lp/signature-maker-professional": {
        "background": "#080808"
    },
    "lp/signature-makers": {
        "background": "#080808"
    },
    "lp/signature-making": {
        "background": "#080808"
    },
    "lp/signature-of-your-name": {
        "background": "#080808"
    },
    "lp/signature-options-for-your-name": {
        "background": "#080808"
    },
    "lp/signature-pro-services": {
        "background": "#080808"
    },
    "lp/signature-professional": {
        "background": "#080808"
    },
    "lp/signature-service": {
        "background": "#080808"
    },
    "lp/signature-suggestions-by-name": {
        "background": "#080808"
    },
    "lp/signatures-for-names": {
        "background": "#080808"
    },
    "lp/signing-autographs": {
        "background": "#080808"
    },
    "lp/turn-your-name-into-a-signature": {
        "background": "#080808"
    },
    "lp/unique-signature": {
        "background": "#080808"
    },
    "lp/unique-signature-creator": {
        "background": "#080808"
    },
    "lp/unique-signature-design": {
        "background": "#080808"
    },
    "lp/unique-signature-generator": {
        "background": "#080808"
    },
    "lp/unique-signature-ideas-for-your-name": {
        "background": "#080808"
    },
    "lp/unique-signature-maker": {
        "background": "#080808"
    },
    "lp/upgrade-your-signature": {
        "background": "#080808"
    },
    "lp/what-is-a-good-signature": {
        "background": "#080808"
    },
    "lp/what-is-a-professional-signature": {
        "background": "#080808"
    },
    "lp/written-signature-maker": {
        "background": "#080808"
    },
    "lp/your-handwritten-premium-signature": {
        "background": "#080808"
    },
    "5xx": {
        "background": "#080808"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
